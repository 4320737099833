import React from "react"
import logo from '../images/assets/csuf-wordmark-reversed.svg'

export default () => (
  <React.Fragment>
    <footer className="site-footer">
      <div className="row">
        <div className="col-8">
          <article className="footerCopy">
            <img src={logo} alt="Cal State Fullerton" style={{width: '200px'}} />
            <p>This site is maintained by <a href="http://www.fullerton.edu/advancement">Division of University Advancement</a><br />
            To report problems or to comment about this site, please contact <a href="mailto:strategiccomm@fullerton.edu">strategiccomm@fullerton.edu</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="https://www.fullerton.edu/it/iso/policy/online-privacy.php">Privacy Policy</a><br />
          © California State University, Fullerton. All Rights Reserved.</p>
        </article>
        <article className="footerResources">
            <h2>Additional Resources</h2>
          <div className="col3">
            <ul>
            <li><a href="http://emergency.fullerton.edu">Emergency Information</a></li>
            <li><a href="https://police.fullerton.edu/">University Police</a></li>	
            <li><a href="http://www.fullerton.edu/ati">CSUF Accessibility</a> &nbsp; | &nbsp; <a href="http://www.fullerton.edu/ati/reportati">Feedback Form</a></li>	 
              <li><span style={{display: 'none'}}>Download Adobe Acrobat Reader </span><a href="https://get.adobe.com/reader/" title="download a reader for PDF documents">Acrobat Reader</a> &nbsp; | &nbsp; <span style={{display: 'none'}}>Download Word viewer, or download Excel viewer, or download PowerPoint viewer </span><a href="https://www.microsoft.com/en-us/download/" title="find sofware for reading Microsoft documents">Microsoft Viewer</a></li> 
            </ul>
          </div>
            <div className="col3">
            <ul>
              <li><a href="https://www.fullerton.edu/it/iso/policy/online-privacy.php">Privacy Policy</a></li>
              <li><a href="https://police.fullerton.edu/jeanneclery">Annual Security Report (Clery)</a></li>
              <li><a href="http://www.fullerton.edu/titleix/">Title IX</a></li>
            </ul>
            </div>
            <div className="col3">
            <ul>
              <li><a href="https://www.fullerton.edu/contact.aspx">Contacts and Addresses</a></li>
              <li><a href="https://www.fullerton.edu/a-z.aspx">Site Index</a></li>
              <li><a href="http://www.fullerton.edu/campusmap/">Campus Map</a></li>
              <li><a href="https://www2.calstate.edu/">CSU</a></li>
            </ul>
            </div>
          </article>
        </div>
        <div className="col-4 staff-credits">

          <ul>
            <li className="staff-list-item">
              <span className="title">President</span>
              <span className="name">Dr. Sylvia A. Alva</span>
            </li>
  
            <li className="staff-list-item">
              <span className="title">Interim Vice President & Executive Director, CSFPF</span>
              <span className="name">Kimberly Shiner</span>
            </li>
  
            <li className="staff-list-item">
              <span className="title">Associate Vice President, Strategic Communications and Brand Management</span>
              <span className="name">Ellen Treanor</span>
            </li>
  
            <li className="staff-list-item">
              <span className="title">Editorial</span>
              <span className="name">Taylor Arrey, Lynn Juliano, Debra Cano Ramos</span>
            </li>
  
            <li className="staff-list-item">
              <span className="title">Design and Visuals</span>
              <span className="name">Howard Chang, Matt Gush, Robert Huskey, Mike Ibanez, Johnny Moon, Robert Rodriguez, Mishu Vu</span>
            </li>
  
            <li className="staff-list-item">
              <span className="title">Production</span>
              <span className="name">Jillian Brander, Denise Del Cid, Michael Mahi, Savannah Terrazas</span>
            </li>
          </ul>

        </div>
      </div>
    </footer>
  </React.Fragment>
);